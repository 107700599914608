* {
    box-sizing: border-box;
}

[class*="col-"] {
    width: 100%;
}

@media only screen and (min-width: 950px) {

    /* For desktop: */
    .col-1 {
        width: 8.33%;
    }

    .col-2 {
        width: 16.66%;
    }

    .col-3 {
        width: 25%;
    }

    .col-4 {
        width: 33.33%;
    }

    .col-5 {
        width: 41.66%;
    }

    .col-6 {
        width: 50%;
    }

    .col-7 {
        width: 58.33%;
    }

    .col-8 {
        width: 66.66%;
    }

    .col-9 {
        width: 75%;
    }

    .col-10 {
        width: 83.33%;
    }

    .col-11 {
        width: 91.66%;
    }

    .col-12 {
        width: 100%;
    }

    @font-face {
        font-family: "Pacifico";
        src: url("./fonts/Pacifico.ttf") format("truetype");
    }

    @font-face {
        font-family: "NationalPark";
        src: url("./fonts/NationalPark.ttf") format("truetype");
    }

    body {
        font-size: calc(15px + 0.390625vw);
        background-color: #A2AC94;
        background-size: contain;
        height: 100vh;
        width: 100vw;
        overflow-x: hidden;
        overflow-y: hidden
    }

    /* Nav CSS */

    nav.navbar.navbar-expand-md.justify-content-center.nav-splash {
        background-color: #A2AC94 !important;
        font-family: Pacifico;
    }

    nav.navbar.navbar-expand-md.justify-content-center.nav-other {
        background-color: #3E4437 !important;
        font-family: Pacifico;
        display: flex;
    }

    ul {
        list-style-type: none;
        text-decoration: none;
        margin: 0;
        padding: 0;
    }

    li {
        margin-left: 2.5vw;
        margin-right: 2.5vw;
        font-family: Pacifico;
        font-size: 3em;
    }

    a:hover {
        color: orangered !important;
        font-family: Pacifico;
    }

    a:link {
        color: white;
        font-size: 1em;
        font-family: Pacifico;
        text-decoration: none;
    }

    a:visited {
        color: white;
        font-size: 1em;
        font-family: Pacifico;
        text-decoration: none;
    }

    /* Splash CSS */

    div.splash-bg {
        height: 100vh;
        width: 100vw;
        background-repeat: repeat-x;
        background-color: #A2AC94;
        background-size: cover;
        font-family: Pacifico;
        text-decoration: none;
    }

    img.splash.splash-graphic {
        transform: translateY(60%);
        height: 50%;
    }

    img.splash.splash-logo {
        transform: translateY(65%);
        height: 50%;
    }

    /* About CSS */

    div.about-bg {
        height: 100vh;
        width: 100vw;
        background-repeat: repeat;
        background-color: #A2AC94;
        background-size: cover;
        font-family: Pacifico;
        text-decoration: none;
    }

    div.about.container {
        width: 100%;
        padding: 0% 0% 0% 0%;
        transform: translateY(25%);
    }

    .about.cursive {
        font-family: Pacifico;
        font-size: 4em;
        color: #3E4437;
        padding: 4em 0em 0em 0em;
    }

    .about.aText {
        text-align: left;
        padding: 4em;
        padding: 1em 2em 1em 0em;
        color: #3E4437;
        font-family: NationalPark;
        font-size: 1.25em;
    }

    .about.aPic {
        padding: 4em 1em 0em 0em;
    }

    /* Contact CSS */

    div.contact-bg {
        height: 100vh;
        width: 100vw;
        background-repeat: repeat;
        background-color: #A2AC94;
        background-size: cover;
        font-family: NationalPark;
        text-decoration: none;
    }

    div.contact.container {
        justify-content: center;
        width: 100%;
        text-align: center;
        color: white;
    }

    .contact.row {
        transform: translateY(25%);
    }

    img {
        transform: translateY(-15%);
        width: 93%;
        height: 93%;
    }

    div.contact.form-container {
        width: 100%;
    }

    form.contact {
        width: 80%;
        padding: 0%;
        margin: 2.5% 0%;
    }

    input#emailInput.contact.form-control,
    input#firstNameInput.contact.form-control,
    input#lastNameInput.contact.form-control {
        background-color: #3E4437;
        border-color: #3E4437;
        color: white;
        margin: 1.5% 0%;
    }

    input#emailInput.contact.form-control:focus,
    input#firstNameInput.contact.form-control:focus,
    input#lastNameInput.contact.form-control:focus {
        background-color: #3E4437;
        border-color: #3E4437;
        color: white;
        font-family: NationalPark;
        margin: 1.5% 0%;
    }

    input#emailInput.contact.form-control::placeholder,
    input#firstNameInput.contact.form-control::placeholder,
    input#lastNameInput.contact.form-control::placeholder {
        color: white;
        margin: 1.5% 0%;
    }

    input#emailInput.contact.form-control:hover::placeholder,
    input#firstNameInput.contact.form-control:hover::placeholder,
    input#lastNameInput.contact.form-control:hover::placeholder {
        color: white;
        font-family: NationalPark;
        margin: 1.5% 0%;
    }

    textarea#comments.contact.form-control {
        resize: none;
        margin: 1.5% 0%;
        background-color: #3E4437;
        border-color: #3E4437;
        color: white;
        font-family: NationalPark;
        margin: 1.5% 0%;
    }

    textarea#comments.contact.form-control:focus {
        resize: none;
        margin: 1.5% 0%;
        background-color: #3E4437;
        border-color: #3E4437;
        color: white;
        font-family: NationalPark;
        margin: 1.5% 0%;
    }

    textarea#comments.contact.form-control::placeholder {
        resize: none;
        margin: 1.5% 0%;
        background-color: #3E4437;
        border-color: #3E4437;
        color: white;
        margin: 1.5% 0%;
    }

    textarea#comments.contact.form-control:hover::placeholder {
        resize: none;
        margin: 1.5% 0%;
        background-color: #3E4437;
        border-color: #3E4437;
        color: white;
        font-family: NationalPark;
        margin: 1.5% 0%;
    }

    h1.contact.conTitle {
        text-align: center;
        font-family: Pacifico;
        color: #3E4437;
        font-size: 4em;
        padding: 2.5% 0% 0% 0%;
    }

    input.contact.btn.submit,
    input.contact.btn.reset {
        background: #3E4437;
        border-color: #3E4437;
        width: 45%;
        margin: 5% 2%;
        margin: 5% 2%;
        color: white;
        font-family: Pacifico;
    }

    input.contact.btn.submit:hover,
    input.contact.btn.reset:hover {
        background-color: #3E4437;
        border-color: #3E4437;
        color: orangered !important;
        width: 45%;
        margin: 5% 2%;
        font-family: NationalPark;
    }

    input.contact.btn.submit:active,
    input.contact.btn.reset:active {
        background-color: #3E4437;
        color: slategray !important;
        width: 45%;
        margin: 5% 2%;
    }

    /* project CSS */

    div.project-bg {
        height: 100vh;
        width: 100vw;
        background-color: #A2AC94;
        font-family: NationalPark;
        text-decoration: none;
        overflow: hidden;
    }

    h1.project.projTitle {
        color: #3E4437;
    }

    h4.project.nfo {
        text-align: center;
        font-weight: bold;
        color: #3E4437;
        width: 100%;
        font-family: NationalPark;
        font-size: .93em;
        padding: 2.5%;
    }


    div.project.container {
        justify-content: center;
        width: 100%;
        height: 100%;
        text-align: left;
        color: white;
    }

    .project.pPic {
        padding: 3em 0em 0em 0em;
        text-align: center;
        font-size: .93em;
        text-decoration: underline;
    }


    button.project.nfo.btn {
        background: #3E4437;
        border-color: #3E4437;
        width: 80%;
        color: white;
        font-family: NationalPark;
        font-size: .93em;
        margin: 2.5% 0%;
    }

    button.project.nfo.btn:active {
        background-color: #3E4437;
        color: slategray;
        width: 80%;
        font-size: .93em;
        margin: 2.5% 0%;
    }

    button.project.nfo.btn:hover {
        background-color: #3E4437;
        border-color: #3E4437;
        color: orangered !important;
        width: 80%;
        font-size: .93em;
        margin: 2.5% 0%;
    }

    button.project.nfo.btn:focus {
        background-color: #3E4437;
        color: tomato;
        width: 80%;
        font-size: .93em;
        margin: 2.5% 0%;
    }


    .project.projTitle {
        text-align: center;
        font-family: Pacifico;
        font-size: 4em;
        margin: 2.5% 0% 0% 0%;
    }
}











@media only screen and (max-width: 768px) {
    .mobile-reduction {
        display: none;
    }

    @font-face {
        font-family: "Pacifico";
        src: url("./fonts/Pacifico.ttf") format("truetype");
    }

    @font-face {
        font-family: "NationalPark";
        src: url("./fonts/NationalPark.ttf") format("truetype");
    }

    body {
        font-size: calc(15px + 0.390625vw);
        background-color: #A2AC94;
        background-size: contain;
        height: 100vh;
        width: 100vw;
        overflow-x: hidden;
    }

    /* Nav CSS */

    nav.navbar.justify-content-center.nav-splash {
        background-color: #3E4437;
        font-family: Pacifico;
        height: 3.8em;
    }

    nav.navbar.justify-content-center.nav-other {
        background-color: #3E4437;
        font-family: Pacifico;
        height: 3.8em;
    }

    .collapse {
        background-color: #3E4437;
        ;
    }

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
    }

    ul {
        background-color: #3E4437;
        list-style-type: none;
        text-decoration: none;
        margin: 0;
        padding: 0;
        justify-content: center;
    }

    li {
        margin-left: 2%;
        margin-right: 2%;
        font-family: Pacifico;
        font-size: 1.2em;
    }

    a:hover {
        color: orangered !important;
        font-family: Pacifico;
        width: 100%;
    }

    a:link {
        color: white;
        font-size: .93em;
        font-family: Pacifico;
        text-decoration: none;
        width: 100%;
    }

    a:visited {
        color: white;
        font-size: .93em;
        font-family: Pacifico;
        text-decoration: none;
        width: 100%;
    }

    /* Splash CSS */

    div.splash-bg {
        height: 100vh;
        width: 100vw;
        background-color: #A2AC94;
        font-family: Pacifico;
        text-decoration: none;
        overflow: hidden;
    }

    img.splash.splash-graphic {
        transform: translateY(10%);
        height: 12em;
        margin: auto;
    }

    img.splash.splash-logo {
        transform: translateY(15%);
        height: 9em;
        margin: auto;
    }

    /* About CSS */

    div.about-bg {
        height: 100vh;
        width: 100vw;
        overflow: hidden;
        background-color: #A2AC94;
        background-color: #A2AC94;
        background-size: cover;
        font-family: Pacifico;
        text-decoration: none;
    }

    div.about.container {
        width: 100%;
        padding: 10% 0% 0% 0%;
        text-align: center;
    }

    .about.cursive {
        font-family: Pacifico;
        font-size: 2em;
        color: #3E4437;
        padding: 4em 0em 4em 0em;
    }

    img {
        padding: 5%;
    }

    .about.aText {
        text-align: center;
        padding: 1em;
        padding: 1.0em 1.0em 0em 1.0em;
        color: #3E4437;
        font-family: NationalPark;
        font-size: .93em;
    }

    /* Contact CSS */

    div.contact-bg {
        height: 100vh;
        width: 100vw;
        background-repeat: repeat;
        background-color: #A2AC94;
        background-size: cover;
        font-family: NationalPark;
        text-decoration: none;
        overflow: hidden;
    }

    div.contact.container {
        justify-content: center;
        width: 100%;
        text-align: center;
        color: white;
    }

    div.contact.form-container {
        width: 100%;
    }

    form.contact {
        width: 100%;
        padding: 0%;
        margin: 5% 0%;
    }

    input#emailInput.contact.form-control,
    input#firstNameInput.contact.form-control,
    input#lastNameInput.contact.form-control {
        background-color: #3E4437;
        border-color: #3E4437;
        color: white;
        font-family: Pacifico;
        margin: 2% 0%;
    }

    input#emailInput.contact.form-control:focus,
    input#firstNameInput.contact.form-control:focus,
    input#lastNameInput.contact.form-control:focus {
        background-color: #3E4437;
        border-color: #3E4437;
        color: white;
        font-family: NationalPark;
        margin: 2% 0%;
    }

    input#emailInput.contact.form-control::placeholder,
    input#firstNameInput.contact.form-control::placeholder,
    input#lastNameInput.contact.form-control::placeholder {
        color: white;
        font-family: Pacifico;
        margin: 2% 0%;
    }

    input#emailInput.contact.form-control:hover::placeholder,
    input#firstNameInput.contact.form-control:hover::placeholder,
    input#lastNameInput.contact.form-control:hover::placeholder {
        color: white;
        font-family: NationalPark;
        margin: 2% 0%;
    }

    textarea#comments.contact.form-control {
        resize: none;
        margin: 1.5% 0%;
        background-color: #3E4437;
        border-color: #3E4437;
        color: white;
        font-family: NationalPark;
        margin: 1.5% 0%;
    }

    textarea#comments.contact.form-control:focus {
        resize: none;
        margin: 1.5% 0%;
        background-color: #3E4437;
        border-color: #3E4437;
        color: white;
        font-family: NationalPark;
        margin: 2% 0%;
    }

    textarea#comments.contact.form-control::placeholder {
        resize: none;
        margin: 1.5% 0%;
        background-color: #3E4437;
        border-color: #3E4437;
        color: white;
        font-family: Pacifico;
        margin: 2% 0%;
    }

    textarea#comments.contact.form-control:hover::placeholder {
        resize: none;
        margin: 1.5% 0%;
        background-color: #3E4437;
        border-color: #3E4437;
        color: white;
        font-family: NationalPark;
        margin: 2% 0%;
    }

    h1.contact.conTitle {
        text-align: center;
        font-family: Pacifico;
        color: #3E4437;
        font-size: 2em;
        padding: 5% 0% 5% 0%;
    }

    input.contact.btn.submit,
    input.contact.btn.reset {
        background: #3E4437;
        border-color: #3E4437;
        width: 45%;
        margin: 5% 2%;
        margin: 5% 2%;
        color: white;
        font-family: Pacifico;
    }

    input.contact.btn.submit:hover,
    input.contact.btn.reset:hover {
        background-color: #3E4437;
        border-color: #3E4437;
        color: orangered !important;
        width: 45%;
        margin: 5% 2%;
        font-family: NationalPark;
    }

    input.contact.btn.submit:active,
    input.contact.btn.reset:active {
        background-color: #3E4437;
        color: slategray !important;
        width: 45%;
        margin: 5% 2%;
    }

    /* project CSS */

    div.project-bg {
        height: 100vh;
        width: 100vw;
        background-repeat: repeat;
        background-color: #A2AC94;
        background-size: cover;
        font-family: NationalPark;
        text-decoration: none;
        overflow: hidden;
    }

    h1.project.projTitle {
        text-align: center;
        font-family: Pacifico;
        font-size: 2em;
        margin: 5% 0% 0% 0%;
        color: #3E4437;
        text-decoration: underline;
    }

    h4.project.nfo {
        text-align: center;
        font-weight: bold;
        color: #3E4437;
        width: 100%;
        font-family: NationalPark;
        font-size: .93em;
        padding: 2.5%;
    }


    div.project.container {
        justify-content: center;
        width: 100%;
        height: 100%;
        text-align: left;
        color: white;
    }

    .project.pPic {
        padding: 1em 0em 0em 0em;
        text-align: center;
        font-size: .93em;
        text-decoration: solid;
    }


    button.project.nfo.btn {
        background: #3E4437;
        border-color: #3E4437;
        width: 100%;
        color: white;
        font-family: NationalPark;
        font-size: .93em;
        margin: 2.5% 0%;
    }

    button.project.nfo.btn:active {
        background-color: #809C52;
        color: saddlebrown;
        width: 100%;
        font-size: .93em;
        margin: 2.5% 0%;
    }

    button.project.nfo.btn:hover {
        background-color: #3E4437;
        border-color: #3E4437;
        color: saddlebrown !important;
        width: 100%;
        font-size: .93em;
        margin: 2.5% 0%;
    }

    button.project.nfo.btn:focus {
        background-color: #809C52;
        color: saddlebrown;
        width: 100%;
        font-size: .93em;
        margin: 2.5% 0%;
    }
}